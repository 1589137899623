import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import authBg from '../assets/images/auth-bg.jpg';
import logo from "../assets/images/logo.png";
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../store/user/userSlice';
import axios from 'axios';

const Login = () => {

    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const login = async (values) => {
        setLoading(true);
        try {
            const resp = await axios.post("https://alkhairifood.com/foodservices/customerlogin.php", values);
            if (resp.data.success) {
                dispatch(setCurrentUser(resp.data.user));
            } else {
                api.error({ message: "Success", description: "Invalid Credentials   " });
            }
        } catch (error) {
            api.error({ message: "Error", description: "Something Went Wrong" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="min-h-screen flex flex-col md:flex-row">
            {contextHolder}
            <div className="relative hidden md:block md:w-1/2">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${authBg})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-[0.7]"></div>
                </div>
            </div>

            <div className="w-full md:w-1/2 p-8 ">
                <img src={logo} alt="optics" className='w-[130px]' />
                <div className="flex justify-center items-center h-[80vh]">
                    <div className="w-full max-w-md">
                        <h2 className="text-3xl font-bold pb-2 text-secondary">Login</h2>
                        <hr className='h-[3px] w-[90px] bg-primary mb-8' />
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            onFinish={login}
                            layout="vertical"
                            size='large'
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input
                                    autoComplete='email'
                                    placeholder="Email"
                                    className='p-3'
                                    prefix={<UserOutlined className='mr-2' />}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password
                                    className='p-3'
                                    placeholder="Password"
                                    autoComplete='current-password'
                                    prefix={<LockOutlined className='mr-2' />}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loading} disabled={loading} type="primary" htmlType="submit" className="w-full text-lg py-2 mt-3">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
