import React, { useState, useMemo } from 'react';
import { Table, Tabs, Input, DatePicker, Button } from 'antd';
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;

const CtaTable = ({ data }) => {
    const uniqueOffers = useMemo(() => {
        return [...new Set(data.map(item => item.offer))];
    }, [data]);

    // State to handle active tab
    const [activeOffer, setActiveOffer] = useState(uniqueOffers[0]);

    // Filter states for each offer
    const [filters, setFilters] = useState({
        [uniqueOffers[0]]: { customerName: '', customerEmail: '', dateRange: [null, null] },
    });

    const handleFilterChange = (offer, key, value) => {
        setFilters(prev => ({
            ...prev,
            [offer]: { ...prev[offer], [key]: value },
        }));
    };

    const filteredData = useMemo(() => {
        return data.filter(item => {
            const itemDate = item.datetime.split(' ')[0];
            const currentFilters = filters[activeOffer] || { customerName: '', customerEmail: '', dateRange: [null, null] };

            const matchesOffer = item.offer === activeOffer;
            const matchesName = item.customername.toLowerCase().includes(currentFilters.customerName.toLowerCase());
            const matchesEmail = item.customeremail.toLowerCase().includes(currentFilters.customerEmail.toLowerCase());
            const matchesDateRange = currentFilters.dateRange[0] && currentFilters.dateRange[1]
                ? new Date(itemDate) >= currentFilters.dateRange[0] && new Date(itemDate) <= currentFilters.dateRange[1]
                : true;

            return matchesOffer && matchesName && matchesEmail && matchesDateRange;
        });
    }, [activeOffer, filters, data]);

    const columns = [
        { title: 'Customer Name', dataIndex: 'customername', key: 'customername' },
        { title: 'Email', dataIndex: 'customeremail', key: 'customeremail' },
        { title: 'Phone', dataIndex: 'customerphone', key: 'customerphone' },
        { title: 'Existing Provider', dataIndex: 'existingprovider', key: 'existingprovider' },
        { title: 'Lines', dataIndex: 'howmanylines', key: 'howmanylines' },
        { title: 'Province', dataIndex: 'province', key: 'province' },
        { title: 'Date & Time', dataIndex: 'datetime', key: 'datetime' },
        { title: "Monthly Spend", dataIndex: "monthlyspend", key: 'monthlyspend' },
        { title: "Services", dataIndex: "services", key: 'services' },
        { title: "Message", dataIndex: "additionalnotes", key: 'additionalnotes' },


    ];

    const handleExport = () => {
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Filtered Data');

        // Use the activeOffer for the file name
        const fileName = `${activeOffer.replace(/[^a-zA-Z0-9]/g, '_')}_Filtered_Data.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const tabItems = uniqueOffers.map(offer => ({
        key: offer,
        label: offer,
        children: (
            <div>
                <div className="filter-container mb-4">
                    <Input
                        placeholder="Customer Name"
                        value={filters[offer]?.customerName || ''}
                        onChange={e => handleFilterChange(offer, 'customerName', e.target.value)}
                        style={{ width: '200px', marginRight: '10px' }}
                    />
                    <Input
                        placeholder="Email"
                        value={filters[offer]?.customerEmail || ''}
                        onChange={e => handleFilterChange(offer, 'customerEmail', e.target.value)}
                        style={{ width: '200px', marginRight: '10px' }}
                    />
                    <RangePicker
                        onChange={dates => {
                            handleFilterChange(offer, 'dateRange', dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null]);
                        }}
                        style={{ marginRight: '10px' }}
                    />
                    <Button disabled={filteredData.length < 1} type="primary" onClick={handleExport}>
                        Export
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey="id"
                    className="ant-responsive-table"
                />
            </div>
        ),
    }));

    return (
        <div>
            <Tabs
                activeKey={activeOffer}
                onChange={key => {
                    setActiveOffer(key);
                    // Reset filter states for the new tab if needed
                    if (!filters[key]) {
                        setFilters(prev => ({ ...prev, [key]: { customerName: '', customerEmail: '', dateRange: [null, null] } }));
                    }
                }}
                items={tabItems}
                className="mb-4"
                centered
            />
        </div>
    );
};

export default React.memo(CtaTable);
