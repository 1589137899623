import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import Login from './screens/Login';
import { useSelector } from 'react-redux';

function App() {

  const { currentUser } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route path="/" element={currentUser ? <Home /> : <Login />} />
    </Routes>
  );
}

export default App;
